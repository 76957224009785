import {
	addCookie, apiFunc
} from '@dg/common/lib/common'

export interface AddressDeleteApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 주소 삭제 */
const addressDeleteApi = async (addrArray: number[]) => {
	const response = await apiFunc(`/api/me/address/delete`, `post`, addrArray)

	return response.data as AddressDeleteApiProps
}

export interface AccountApiProps {
	code: number;
	message?: string;
	result: {
		items: {
			account_nm: string;
			account_no2: string;
			account_owner_nm: string;
			account_seq_no: number;
			account_type: string;
			account_type_nm: string;
			bank_cd: string;
			bank_nm: string;
			branch_cd: string;
			branch_nm: string;
			default_account_yn: `N` | `Y`;

		}[];
		page_count: number;
		record_count: number;
	};
}

/* 계좌 정보 가져오기 */
const accountApi = async (pageNo: number, pageSize: number = 5) => {
	const response = await apiFunc(`/api/me/account/page?pageNo=${pageNo}&pageSize=${pageSize}`)

	return response.data as AccountApiProps
}

export interface AccountGetApiProps {
	code: number;
	message?: string;
	result: {
		account_nm: string;
		account_no2: string;
		account_owner_nm: string;
		account_seq_no: number;
		account_type: string;
		account_type_nm: string;
		bank_cd: string;
		bank_nm: string;
		branch_cd: string;
		branch_nm: string;
		default_account_yn: `N` | `Y`;
	};
}

/* 계좌 개별 정보 조회 */
const accountGetApi = async (accountSeqNo: number) => {
	const response = await apiFunc(`/api/me/account?accountSeqNo=${accountSeqNo}`)

	return response.data as AccountGetApiProps
}

export interface AccountDeleteApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 계좌 정보 삭제 */
const accountDeleteApi = async (sellerAccNo: string) => {
	const response = await apiFunc(`/api/me/account/delete/${sellerAccNo}`, `post`)

	return response.data as AccountDeleteApiProps
}

export interface AccountPostApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 계좌 추가 */
const accountPostApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/account`, `post`, Object.fromEntries(formData))

	return response.data as AccountPostApiProps
}

export interface AccountPutApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 계좌 수정 */
const accountPutApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/account/edit`, `post`, Object.fromEntries(formData))

	return response.data as AccountPutApiProps
}

export interface GBankTypeApiProps {
	code: number;
	message?: string;
	result: {
		bank_nm: string;
		gbank_no: number;
	}[];
}

/* 은행 정보 가져오기 */
const gBankTypeApi = async (type: number) => {
	const response = await apiFunc(`/api/legacy/common/gbank/type/${type}`)

	return response.data as GBankTypeApiProps
}

export interface AddressGetApiProps {
	code: number;
	message?: string;
	result: {
		addr_front: string;
		addr_group_no: number;
		addr_last: string;
		addr_nm: string;
		addr_no: number;
		city: string;
		cust_nm: string;
		default_yn: string;
		email: string;
		hp_no: string;
		jp_recv_nm: string;
		jp_recv_nm_first: string;
		jp_recv_nm_last: string;
		nation_cd: string;
		qnumber: string;
		recv_nm: string;
		state: string;
		street: string;
		tel_no: string;
		zip_code: string;
	}[];
}

/* 주소록 목록 */
const addressGetApi = async () => {
	const response = await apiFunc(`/api/me/address`)

	return response.data as AddressGetApiProps
}

export interface AddressGroupGetApiProps {
	code: number;
	message?: string;
	result: {
		addr_group_cnt: number;
		addr_group_nm: string;
		addr_group_no: number;
		addr_group_tempnm: string;
	}[];
}

/* 주소록 그룹 목록 */
const addressGroupGetApi = async () => {
	const response = await apiFunc(`/api/me/address/group`)

	return response.data as AddressGroupGetApiProps
}

interface AddressGroupDataProps {
	data: {
		addr_group_nm: string;
		addr_group_no: number;
	}[];
}

export interface AddressGroupPostApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 주소록 그룹 추가 */
const addressGroupPostApi = async (arrayData: AddressGroupDataProps[`data`]) => {
	const response = await apiFunc(`/api/me/address/groups`, `post`, arrayData)

	return response.data as AddressGroupPostApiProps
}

export interface AddressGroupPutApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 주소록 그룹 수정 */
const addressGroupPutApi = async (arrayData: AddressGroupDataProps[`data`]) => {
	const response = await apiFunc(`/api/me/address/groups/edit`, `post`, arrayData)

	return response.data as AddressGroupPutApiProps
}

export interface AddressGroupDeleteApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 주소록 그룹 삭제 */
const addressGroupDeleteApi = async (arrayData: AddressGroupDataProps[`data`]) => {
	const response = await apiFunc(`/api/me/address/groups/delete`, `post`, arrayData)

	return response.data as AddressGroupDeleteApiProps
}

export interface AddressMoveGroupApiProps {
	code: number;
	message?: string;
	result: boolean;
}

interface AddressMoveGroupDataProps {
	data: {
		addr_group_nm: string;
		addr_nos: number[];
	};
}

/* 주소록 그룹 변경 */
const addressMoveGroupApi = async (arrayData: AddressMoveGroupDataProps[`data`]) => {
	const response = await apiFunc(`/api/me/address/move-group`, `post`, arrayData)

	return response.data as AddressMoveGroupApiProps
}

export interface AddressPostApiProps {
	code: number;
	message?: string;
	result: number;
}

/* 주소 추가 */
const addressPostApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/address`, `post`, Object.fromEntries(formData))

	return response.data as AddressPostApiProps
}

export interface AddressPutApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 주소 수정 */
const addressPutApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/address/edit`, `post`, Object.fromEntries(formData))

	return response.data as AddressPutApiProps
}

export interface ArsCodeApiProps {
	code: number;
	message?: string;
	result: {
		confirm_code: string;
		seq_no: number;
	};
}

/* ARS 인증 코드 */
const arsCodeApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/ars/code`, `post`, Object.fromEntries(formData))

	return response.data as ArsCodeApiProps
}

export interface ArsConfirmApiProps {
	code: number;
	message?: string;
	result: {
		verify_token: string;
	};
}

/* ARS 인증 전화 */
const arsConfirmApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/ars/confirm`, `post`, Object.fromEntries(formData))

	return response.data as ArsConfirmApiProps
}

export interface ArsCheckApiProps {
	code: number;
	message?: string;
	result: `FAIL` | `SUCCESS` | `TOKEN_EXPIRED` | `WAIT`;
}

/* ARS 인증 전화 체크 */
const arsCheckApi = async (verifyToken: string) => {
	const response = await apiFunc(`/api/me/ars/check?verifyToken=${verifyToken}`)

	return response.data as ArsCheckApiProps
}

export interface AuthSnsAppleApiProps {
	code: number;
	message?: string;
	result: {
		email: string;
		name: string;
		uid: string;
	};
}

/* Apple 로그인 추가 정보 */
const authSnsAppleApi = async (token: string) => {
	const response = await apiFunc(`/api/auth/sns/apple?idToken=${token}`)

	return response.data as AuthSnsAppleApiProps
}

export interface BirthdateApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 생년월일 추가/수정 */
const birthdateApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/birthdate`, `post`, Object.fromEntries(formData))

	return response.data as BirthdateApiProps
}

export interface ChangePasswordNotLoginApiProps {
	code: number;
	message: string;
}

const changePasswordNotLoginApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/passwd/not-login`, `post`, Object.fromEntries(formData))

	return response.data as ChangePasswordNotLoginApiProps
}

export interface CheckMemberConfirmCodeApiResponseProps {
	code: number;
	message: string;
	result: boolean;
}

/* 회원 승인 코드 체크 */
const checkMemberConfrimCodeApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/confirm/check`, `post`, Object.fromEntries(formData))

	return response.data as CheckMemberConfirmCodeApiResponseProps
}

export interface ResignCheckJoinApiProps {
	code: number;
	message?: string;
	result: {
		cust_no: string;
		email: string;
		member_kind: string;
		sns_cd: string;
		sns_email: string;
		sns_login_yn: string;
		uid: string;
	};
}

export interface ContactSmsApiProps {
	code: number;
	message?: string;
	result: string;
}

/* 회원정보 HP SMS 인증 코드 발송 */
const contactSmsApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/contact/sms`, `post`, Object.fromEntries(formData))

	return response.data as ContactSmsApiProps
}

export interface ContactSmsConfirmApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 회원정보 HP SMS 인증 완료 및 번호 수정 */
const contactSmsConfirmApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/contact/sms/confirm`, `post`, Object.fromEntries(formData))

	return response.data as ContactSmsConfirmApiProps
}

/* step 1 : [회원탈퇴] 회원의 가입 유형 조회 */
const resignCheckJoinApi = async () => {
	const response = await apiFunc(`/api/me/dropout/join/check`, `post`)

	return response.data as ResignCheckJoinApiProps
}

export interface ResignCheckPasswordApiProps {
	code: number;
	message?: string;
	result: string;
}

/* step 2 : [회원탈퇴] 패스워드 체크 */
const resignCheckPasswordApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/dropout/password/check`, `post`, Object.fromEntries(formData))

	return response.data as ResignCheckPasswordApiProps
}

export interface ResignCheckConditionApiProps {
	code: number;
	message?: string;
	result: {
		cnt: string;
		return_code: string;
	}[];
}

/* step 3 : [회원탈퇴] 탈퇴 가능여부 체크 */
const resignCheckConditionApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/dropout/condition/check`, `post`, Object.fromEntries(formData))

	return response.data as ResignCheckConditionApiProps
}

export interface ResignProcessApiProps {
	code: number;
	message?: string;
	result: number;
}

/* step 4 : [회원탈퇴] 탈퇴 처리 */
const resignProcessApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/dropout/process`, `post`, Object.fromEntries(formData))

	return response.data as ResignCheckConditionApiProps
}

export interface EditApiProps {
	code: number;
	message?: string;
	result: {
		account_count: number;
		account_no: string;
		account_owner_nm: string;
		affiliate_contract_cd: string;
		bank_nm: string;
		birth_date: string;
		black_list: boolean;
		branch_cd: string;
		branch_nm: string;
		buyer_grade: `B1` | `G1` | `N1` | `R1` | `V1` | `V2`; // B1: 블랙리스트, G1: Gray, N1: 일반, R1: Silver, V1: Gold, V2: Vip
		confirm: boolean; // email_confirm_yn, mobile_confirm_yn 둘중 하나라도 Y이면 true || email 인증 받은 이력이 하나라도 있으면 true
		credit_point: number;
		cust_grade: `A1` | `B1` | `C1` | `D1` | `D2` | `X1`; // A1: 파워 이딜러, B1: 우수 이딜러, C1: 일반 이딜러, D1: 불량 이딜러, D2: 블랙리스트, X1: 퇴출 이딜러
		cust_nm: string;
		cust_type: `EC` | `EG` | `EP` | `PC` | `PP`; // EC: 법인셀러, EG: 글로벌셀러, EP: 개인셀러, PC: 법인구매자, PP: 개인구매자
		domestic_account_yn: `N` | `Y`;
		email: string;
		email_confirm_yn: null | `N` | `Y`;
		email_member_yn: null | `-1` | `1` | `2` | `3` | `4` | `5`;
		gender: `F` | `M` | `U`; // F: 여성, M: 남성, U: 미설정
		has_qsafe_password: boolean;
		hp_email: string;
		hp_no: null | string;
		jp_nm: string;
		jp_nm_first: string;
		jp_nm_last: string;
		latest_account_owner_nm: string;
		login_email: null | string;
		login_hp_no: string;
		login_id: string;
		member_kind_code: `M` | `N`;
		mobile_confirm_yn: `N` | `Y`;
		mobile_news_letter_mail: boolean;
		news_letter_mail: boolean;
		newsletter_weekly_count: number;
		qna_get_way_list: string[];
		qsafe_question: string;
		sell_credit_point: number;
		send_email: null | string;
		shop_coupon_mail: boolean;
		size_height: null | string;
		size_shoes: null | string;
		size_underwear_cup: null | string;
		size_underwear_pants: null | string;
		size_weight: null | string;
		skin_care_codes: null | `A` | `B` | `D` | `M` | `N` | `P` | `W`; // A: Acne 여드름, 피부염, B: Blemish 잡티 , D: Dry, M: Moisture 건조/보습, N: 해당없음, P: Pore 모공, W: Wrinkle 주름
		skin_tone_code: null | `C` | `W`; // C: Cool Tone 쿨톤, W: Warm Tone 웜톤
		skin_type_code: null | `C` | `D` | `O` | `S`; // C: Combination 복합성, D: Dry 건성, O: Oily 지성, S: Senstivity 민감성
		sns_list: {
			sns_cd: string;
			sns_email: string;
		}[];
		sns_login_yn: `N` | `Y`;
		tel_no: null | string;
		tel_no_confirm_yn: null | `N` | `Y`;
		unsubscribe_reason: null | string;
		use_qsafe_password: boolean;
		valid_key?: string;
	};
}

/* 회원정보 */
const editApi = async (type: `client` | `server` = `client`, data?: Record<string, unknown>) => {
	const response = await apiFunc(`${type === `server` ? `${process.env.apiUrl}` : `/api`}/me/edit`, `get`, data)

	return response.data as EditApiProps
}

/* 회원정보 - 인증 후 */
const editValidApi = async (validKey: string) => {
	const response = await apiFunc(`/api/me/edit-pc?validKey=${validKey}`)

	return response.data as EditApiProps
}

export interface EditBasicApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 회원 정보 수정 */
const editBasicApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/edit/basic`, `post`, Object.fromEntries(formData))

	return response.data as EditBasicApiProps
}

export interface EmailApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Email 변경 */
const emailApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/email`, `post`, Object.fromEntries(formData))

	return response.data as EmailApiProps
}

export interface EmailValidStateApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Email 변경 취소 여부 */
const emailValidStateApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/email/valid-state`, `post`, Object.fromEntries(formData))

	return response.data as EmailValidStateApiProps
}

export interface GetPhoneAuthenticationPageApiProps {
	code: number;
	message: string;
	result: {
		email: string;
		enc_cust_info: string;
		hp_no_login_yn: string;
		login_hp_no: string;
	};
}

const getPhoneAuthenticationPageApi = async (encCustNo: string) => {
	const response = await apiFunc(`/api/member/phone-authentication?encCustNo=${encCustNo}`)

	return response.data as GetPhoneAuthenticationPageApiProps
}

export interface GoodsApiResponseProps {
	code: number;
	result: {
		cost_basis_no_of_goods: number;
		deal_price: number;
		deal_price_kind: string;
		ex_type: string;
		gd_nm: string;
		gd_no: string;
		gd_sub_type: string;
		gd_type: string;
		gdsc_cd: string;
		img_contents_no: number;
		inventory_no: string;
		link_type: string;
		min_sell_amt: number;
		purchase_unit: number;
		sell_price: number;
		seller_cust_no: string;
		stat: string;
		time_sale_yn: string;
		trad_way: string;
	};
}

/* GET 상품 정보  */
const goodsApi = async (code: string) => {
	const response = await apiFunc(`/api/legacy/goods/${code.toString()}`)

	return response.data as GoodsApiResponseProps
}

export interface GoogleMapGeoCodeApiProps {
	results: {
		address_components: {
			long_name?: string;
			short_name?: string;
			types?: string[];
		}[];
		formatted_address: string;
	}[];
}

/* 구글 맵 위치 정보 */
const googleMapGeoCodeApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api-map?${formData.toString()}`)

	return response.data as GoogleMapGeoCodeApiProps
}

export interface JoinAuthApiProps {
	code: number;
	message?: string;
	result: {
		auth_email: string;
		banner: {
			link: string;
			src: string;
			title: string;
		};
		guest_name: string;
		required_hp_no_login: boolean;
	};
}

/* 인증 정보 검증 */
const joinAuthApi = async (formData: URLSearchParams, login = false) => {
	const response = await apiFunc(`/api/${login === true ? `me` : `member`}/join?${formData.toString()}`)

	return response.data as JoinAuthApiProps
}

export interface JoinHpMailApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* HP 메일 수정 */
const joinHpMailApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/join/hp/mail`, `post`, Object.fromEntries(formData))

	return response.data as JoinHpMailApiProps
}

export interface JoinSmsApiProps {
	code: number;
	message?: string;
	result: string;
}

/* 중복 체크 후 SMS 인증 번호 발송 */
const joinSmsApi = async (formData: URLSearchParams, login = false) => {
	const response =
		await apiFunc(`/api/${login === true ? `me` : `member`}/join/sms`, `post`, Object.fromEntries(formData))

	return response.data as JoinSmsApiProps
}

export interface JoinValidateApiProps {
	code: number;
	message?: string;
	result: {
		guest_email: string;
		guest_hp_no: string;
	};
}

/* Guest 회원 관련 정보 */
const joinValidateApi = async (login = false) => {
	const response = await apiFunc(`/api/${login === true ? `me` : `member`}/join/validate`)

	return response.data as JoinValidateApiProps
}

export interface LoginApiProps {
	code: number;
	message?: string;
	result: {
		custInfo?: string;
		enc_cust_no: boolean | number | string;
		encryptCustNo?: string;
		isRegister?: string;
		memberKind: `M` | `N`;
		mobileKeyValue?: string;
		rememberMe?: string;
	};
}

/* 로그인 */
const loginApi = async (formData: URLSearchParams) => {
	addCookie(`myinfoauth=`, 0)

	const response = await apiFunc(`/api/login`, `post`, Object.fromEntries(formData))

	return response.data as LoginApiProps
}

export interface LoginHistoryApiProps {
	code: number;
	message?: string;
	result: {
		hist_no: number;
		ip: string;
		ip_nation_cd: string;
		login_channel: string;
		reg_dt: string;
	}[];
}

/* 로그인 히스토리 - 최초 50개 호출 */
const loginHistoryApi = async () => {
	const response = await apiFunc(`/api/me/login/history`)

	return response.data as LoginHistoryApiProps
}

/* 로그인 히스토리 - 추가 호출 */
const loginHistoryNextApi = async (nextHistNo = 0) => {
	const response = await apiFunc(`/api/me/login/history/${nextHistNo}`)

	return response.data as LoginHistoryApiProps
}

export interface LoginIdApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* ID 추가/수정 */
const loginIdApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/loginId`, `post`, Object.fromEntries(formData))

	return response.data as LoginIdApiProps
}

/* 다른 기기에서 로그아웃 */
const logoutAllApi = async () => {
	addCookie(`myinfoauth=`, 0)

	const response = await apiFunc(`/api/logout/all`, `post`)

	return response.data as never
}

export interface MailMobileMagazineApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 모바일 매거진 수신 설정 */
const mailMobileMagazineApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/mail/mobile-magazine`, `post`, Object.fromEntries(formData))

	return response.data as MailMobileMagazineApiProps
}

export interface MailShopCouponApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 샵쿠폰 메일 수신 설정 */
const mailShopCouponApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/mail/shopcoupon`, `post`, Object.fromEntries(formData))

	return response.data as MailShopCouponApiProps
}

export interface MailUnsubscribeApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 샵쿠폰 메일 수신 설정 */
const mailUnsubscribeApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/mail/unsubscribe`, `post`, Object.fromEntries(formData))

	return response.data as MailUnsubscribeApiProps
}

export interface MemberConfirmPageApiResponseProps {
	code: number;
	message: string;
	result: {
		cb_login_id: string;
		cust_nm: string;
		email: string;
		email_confirm_yn: string;
		enc_sms_param: string;
		hp_no: string;
		mobile_confirm_yn: string;
		nation_cd: string;
		pw_chg_yn: string;
		sns_login_yn: string;
	};
}

/* MemberConfrimation 페이지 기본 정보 가져오기 */
const memberConfirmPageApi = async (encCustNo: string) => {
	const response = await apiFunc(`/api/member/confirm?encCustNo=${encCustNo}`)

	return response.data as MemberConfirmPageApiResponseProps
}

export interface MemberConfirmPagePostApiResponseProps {
	code: number;
	message: string;
	result: {
		cust_info: string;
		mobile_key_value: string;
	};
}

/* MemberConfirmation 페이지 PostBack API */
const memberConfirmPagePostApi = async (formData: URLSearchParams) => {
	const response =
		await apiFunc(`/api/member/confirm`, `post`, Object.fromEntries(formData))

	return response.data as MemberConfirmPagePostApiResponseProps
}

export interface NameApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 이름, 후리가나 수정 */
const nameApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/name`, `post`, Object.fromEntries(formData))

	return response.data as NameApiProps
}

export interface NewsletterApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Newsletter 수신 변경 */
const newsletterApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/newsletter`, `post`, Object.fromEntries(formData))

	return response.data as NewsletterApiProps
}

export interface PasswdApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Password 변경 */
const passwdApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/passwd`, `post`, Object.fromEntries(formData))

	return response.data as PasswdApiProps
}

export interface ReMailApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Email 재발송 */
const reMailApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/re-mail`, `post`, Object.fromEntries(formData))

	return response.data as ReMailApiProps
}

export interface SmsApiProps {
	code: number;
	message?: string;
	result: string;
}

/* SMS 인증 수정 */
const smsApi = async (formData: URLSearchParams, login = false) => {
	const response =
		await apiFunc(`/api/${login === true ? `me` : `member`}/sms`, `post`, Object.fromEntries(formData))

	return response.data as SmsApiProps
}

export interface SmsConfirmApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* SMS 인증 확인 */
const smsConfirmApi = async (formData: URLSearchParams, login = false) => {
	const response =
		await apiFunc(`/api/${login === true ? `me` : `member`}/sms/confirm`, `post`, Object.fromEntries(formData))

	return response.data as SmsConfirmApiProps
}

export interface SearchAddressApiProps {
	code: number;
	message?: string;
	result: {
		bldg: string;
		city: string;
		nation_cd: string;
		state: string;
		street: string;
		zipcode: string;
	}[];
}

/* 주소 검색 */
const searchAddressApi = async (formData: URLSearchParams, road = false) => {
	const response = await apiFunc(`/api/legacy/search/address${road === true ? `/road` : ``}?${formData.toString()}`)

	return response.data as SearchAddressApiProps
}

export interface SendConfirmCodeEncApiResponseProps {
	code: number;
	message: string;
	result: {
		cust_no: string;
		login_id: string;
		member_kind: string;
		reg_dt: string;
		seq_no: number;
	};
}

/* 휴대폰 인증번호 보내기 */
const sendConfirmCodeEncApi = async (formData: URLSearchParams) => {
	const response =
		await apiFunc(`/api/member/message/enc_confirm_code`, `post`, Object.fromEntries(formData))

	return response.data as SendConfirmCodeEncApiResponseProps
}

export interface SmsVerifyApiProps {
	code: number;
	message?: string;
	result: string;
}

/* SMS 검증 */
const smsVerifyApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/join/sms/verify`, `post`, Object.fromEntries(formData))

	return response.data as SmsVerifyApiProps
}

export interface SnsAddApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* SNS 연동 추가 */
const snsAddApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/sns`, `post`, Object.fromEntries(formData))

	return response.data as SnsAddApiProps
}

export interface SnsDeleteApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* SNS 연동 제거 */
const snsDeleteApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/sns/delete`, `post`, Object.fromEntries(formData))

	return response.data as SnsDeleteApiProps
}

export interface SnsModifyApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* SNS 연동 제거 */
const snsModifyApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/sns`, `post`, Object.fromEntries(formData))

	return response.data as SnsModifyApiProps
}

export interface SpecialApiResponseProps {
	code: number;
	result: {
		buy_cust_no: string;
		onoff: string;
		sell_cust_no: string;
		sid: number;
		title: string;
	};
}

/* GET 기획전 정보 */
const specialApi = async (code: string) => {
	const response = await apiFunc(`/api/special/${code.toString()}`)

	return response.data as SpecialApiResponseProps
}

/* Email 중복 체크 */
export interface ValidationEmailApiProps {
	code: number;
	message?: string;
	result: string;
}

const validationEmailApi = async (formData: URLSearchParams, login = false) => {
	const response = await apiFunc(`/api/${login === true ? `me` : `member`}/validation/email?${formData.toString()}`)

	return response.data as ValidationEmailApiProps
}

export interface ValidationIdApiProps {
	code: number;
	message?: string;
	result: string;
}

/* Id 중복 체크 */
const validationIdApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/validation/id?${formData.toString()}`)

	return response.data as ValidationIdApiProps
}

export interface QsafePasswordApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Q어카운트 Password 추가 */
const qsafePasswordApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/qsafe/password`, `post`, Object.fromEntries(formData))

	return response.data as QsafePasswordApiProps
}

export interface QsafePasswordPutApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Q어카운트 Password 수정 */
const qsafePasswordPutApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/qsafe/password/edit`, `post`, Object.fromEntries(formData))

	return response.data as QsafePasswordPutApiProps
}

export interface QsafeCheckPasswordApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Q어카운트 Password 사용 설정 */
const qsafeCheckPasswordApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/qsafe/check-password?${formData.toString()}`)

	return response.data as QsafeCheckPasswordApiProps
}

export interface QsafeUsePasswordApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Q어카운트 Password 사용 설정 */
const qsafeUsePasswordApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/qsafe/use-password`, `post`, Object.fromEntries(formData))

	return response.data as QsafeUsePasswordApiProps
}

export interface QsafeResetPasswordApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* Q어카운트 Password 재설정 */
const qsafeResetPasswordApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/qsafe/reset-password`, `post`, Object.fromEntries(formData))

	return response.data as QsafeResetPasswordApiProps
}

export interface QCallRequestApiResponseProps {
	code: number;
	message: string;
	result: number;
}

/* 전화 요청 생성 */
const qCallRequestApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/legacy/cs/qcall`, `post`, Object.fromEntries(formData))

	return response.data as QCallRequestApiResponseProps
}

export interface SetPhoneAuthenticationPageApiProps {
	code: number;
	message: string;
	result: {
		cust_info: string;
		mobile_key_value: string;
	};
}

/* 폰 인증 페이지 PostBack API */
const setPhoneAuthenticationPageApi = async (formData: URLSearchParams) => {
	const response =
		await apiFunc(`/api/member/phone-authentication`, `post`, Object.fromEntries(formData))

	return response.data as SetPhoneAuthenticationPageApiProps
}

export interface GetConfirmEmailPageApiProps {
	code: number;
	message: string;
	result: {
		cust_nm: string;
		email: string;
		enc_cust_info: string;
		is_confirm: string;
		nation_cd: string;
	};
}

/* ConfirmEmail_Mobile.aspx / mobile/login/confirmemail 페이지 기본 정보 가져오기 */
const getConfirmEmailPageApi = async (encCustNo: string) => {
	const response = await apiFunc(`/api/member/confirm/email?encCustNo=${encCustNo}`)

	return response.data as GetConfirmEmailPageApiProps
}

export interface SetConfirmEmailPageApiProps {
	code: number;
	message: string;
	result: {
		cust_info: string;
		mobile_key_value: string;
	};
}

/* 이메일 인증 페이지 PostBack API */
const setConfirmEmailPageApi = async (formData: URLSearchParams) => {
	const response =
		await apiFunc(`/api/member/confirm/email`, `post`, Object.fromEntries(formData))

	return response.data as SetConfirmEmailPageApiProps
}

export interface CheckPrimaryIdPageApiProps {
	code: number;
	message: string;
	result: {
		cust_info: string;
		enc_cust_no: string;
		mobile_key_value: string;
	};
}

/* 판매자 주 아이디 체크 */
const checkPrimaryIdPageApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/primary-id`, `post`, Object.fromEntries(formData))

	return response.data as CheckPrimaryIdPageApiProps
}

export interface SetNameOrEmailPageApiProps {
	code: number;
	message: string;
	result: {
		cust_info: string;
		enc_cust_no: string;
		mobile_key_value: string;
	};
}

/* SNS 로그인 시 이름이나 이메일 없을 경우 업데이트 */
const setNameOrEmailPageApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/sns/name-or-email`, `post`, Object.fromEntries(formData))

	return response.data as SetNameOrEmailPageApiProps
}

export interface ConnectCustomByEmailApiProps {
	code: number;
	message: string;
	result: {
		cust_info: string;
		enc_cust_no: string;
		mobile_key_value: string;
	};
}

const connectCustomByEmailApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/sns/connect/email`, `post`, Object.fromEntries(formData))

	return response.data as ConnectCustomByEmailApiProps
}

/* ID찾기 STEP1 : 계정 정보 Partial조회 */
export interface findIdApiProps {
	code: number;
	message: string;
	result: [
		{
			email: string;
			enc_cust_info_type_m: string;
			enc_cust_info_type_s: string;
			enc_cust_nm: string;
			enc_cust_no: string;
			enc_full_email: string;
			enc_full_hp_email: string;
			enc_full_hp_no: string;
			enc_full_login_id: string;
			hp_email: string;
			hp_no: string;
			login_id: string;
			reg_dt: string;
		}
	];
}

const findIdApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/find/id`, `post`, Object.fromEntries(formData))

	return response.data as findIdApiProps
}

/* ID찾기 STEP2 : SMS 인증 코드 확인 */
export interface findCaptchaApiProps {
	code: number;
	message: string;
	result: {
		enc_cust_info_type_m: string;
		enc_cust_info_type_s: string;
		enc_cust_no: string;
		hp_email?: string;
		hp_no: string;
	};
}

const findCaptchaApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/find/id/captcha/confirm`, `post`, Object.fromEntries(formData))

	return response.data as findCaptchaApiProps
}

/* ID/PWD찾기 STEP3: 공통 SMS 인증 코드 발송 */
export interface findSmsSendApiProps {
	code: number;
	message: string;
	result: string;
}

const findSmsSendApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/find/sms/send`, `post`, Object.fromEntries(formData))

	return response.data as findSmsSendApiProps
}

/* STEP3 : ID/PWD 찾기 > 공통 SMS 인증 코드 확인 */
export interface findSmsSendCheckApiProps {
	code: number;
	message: string;
	result: {
		confirm_code: string;
		confirm_cust_key: string;
		email: string;
		login_id: string;
		reg_dt: string;
		seq_no: number;
		sns_cd: string;
		sns_login_yn: string;
		sucess: boolean;
	};
}

const findSmsSendCheckApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/find/sms/confirm`, `post`, Object.fromEntries(formData))

	return response.data as findSmsSendCheckApiProps
}

/* PW찾기 STEP1 : PWD 찾기 > step1 : 계정 정보 Partial 조회 */
export interface findPwdApiProps {
	code: number;
	message: string;
	result: [
		{
			email: string;
			enc_cust_info_type_m: string;
			enc_cust_info_type_s: string;
			enc_cust_nm: string;
			enc_cust_no: string;
			enc_full_email: string;
			enc_full_hp_email: string;
			enc_full_hp_no: string;
			enc_full_login_id: string;
			hp_email: string;
			hp_no: string;
			login_id: string;
			reg_dt: string;
		}
	];
}

const findPwdApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/find/pwd`, `post`, Object.fromEntries(formData))

	return response.data as findPwdApiProps
}

/* PW찾기 STEP2 : PWD 찾기 > step2 : 비밀번호 재설정 이메일 발송 */
export interface findResendMailApiProps {
	code: number;
	message: string;
	result: boolean;
}

const findResendMailApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/find/pwd/reset/mail/send`, `post`, Object.fromEntries(formData))

	return response.data as findResendMailApiProps
}

/* PW찾기 STEP4 : PWD 찾기: 비밀번호 재설정 */
export interface findPasswordResetApiProps {
	code: 0;
	message: string;
	result: {
		code: 0;
		message: string;
		result: {
			birth_date: string;
			buyer_gr: string;
			cust_info: string;
			cust_nm: string;
			cust_no: string;
			cust_type: string;
			email: string;
			encrypt_cust_no: string;
			gender: string;
			hp_no: string;
			is_adult: string;
			is_blacklist: string;
			is_register: string;
			jaehu_cust_no: string;
			login_id: string;
			member_kind: string;
			mobile_key_value: string;
			org_svc_nation_cd: string;
			prime_club_yn: string;
			reg_dt: string;
			remember_me: string;
			return_url: string;
			signup_date: string;
			svc_nation_cd: string;
		};
	};
}

const findPasswordResetApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/find/pwd/reset`, `post`, Object.fromEntries(formData))

	return response.data as findPasswordResetApiProps
}

export interface HpMailApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 회원 Hp Email 설정 */
const hpMailApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/join/hp/mail`, `post`, Object.fromEntries(formData))

	return response.data as HpMailApiProps
}

export {
	addressDeleteApi,
	addressGetApi,
	addressGroupDeleteApi,
	addressGroupGetApi,
	addressGroupPostApi,
	addressGroupPutApi,
	addressMoveGroupApi,
	addressPostApi,
	addressPutApi,
	arsCodeApi,
	arsConfirmApi,
	arsCheckApi,
	authSnsAppleApi,
	accountApi,
	accountDeleteApi,
	accountGetApi,
	accountPostApi,
	accountPutApi,
	gBankTypeApi,
	birthdateApi,
	changePasswordNotLoginApi,
	checkMemberConfrimCodeApi,
	contactSmsApi,
	contactSmsConfirmApi,
	resignCheckJoinApi,
	resignCheckPasswordApi,
	resignCheckConditionApi,
	resignProcessApi,
	checkPrimaryIdPageApi,
	connectCustomByEmailApi,
	editApi,
	editValidApi,
	editBasicApi,
	emailApi,
	emailValidStateApi,
	getPhoneAuthenticationPageApi,
	goodsApi,
	googleMapGeoCodeApi,
	joinAuthApi,
	joinHpMailApi,
	joinSmsApi,
	joinValidateApi,
	loginApi,
	loginHistoryApi,
	loginHistoryNextApi,
	loginIdApi,
	logoutAllApi,
	mailMobileMagazineApi,
	mailShopCouponApi,
	mailUnsubscribeApi,
	memberConfirmPageApi,
	memberConfirmPagePostApi,
	nameApi,
	newsletterApi,
	passwdApi,
	reMailApi,
	searchAddressApi,
	sendConfirmCodeEncApi,
	smsApi,
	smsConfirmApi,
	smsVerifyApi,
	snsAddApi,
	snsDeleteApi,
	snsModifyApi,
	specialApi,
	validationEmailApi,
	validationIdApi,
	qsafePasswordApi,
	qsafePasswordPutApi,
	qsafeCheckPasswordApi,
	qsafeResetPasswordApi,
	qsafeUsePasswordApi,
	qCallRequestApi,
	setPhoneAuthenticationPageApi,
	getConfirmEmailPageApi,
	setConfirmEmailPageApi,
	setNameOrEmailPageApi,
	findIdApi,
	findCaptchaApi,
	findPwdApi,
	findResendMailApi,
	findSmsSendApi,
	findSmsSendCheckApi,
	findPasswordResetApi,
	hpMailApi
}
